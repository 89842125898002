
import React, { useState,useEffect } from 'react';
import {Dropdown,Button} from 'react-bootstrap'

import logo from '../../Assets/Images/logoadmins.png'
import prof from '../../Assets/Images/prof1.jpg'
import bar from '../../Assets/Images/menu.png'
import ConnectWallet from '../Modals/ConnectWallet';
import {UseProvider} from "../../hooks/useWeb3"
import {style,iconTheme,position} from '../../hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';
import {getAccount} from "../../hooks/useAccounts";
import { logouthook } from '../../hooks/adminHook';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { API_URL } from '../../Config/env';
const cookies = new Cookies();

const AdminHeader = () =>{
    const navigate = useNavigate();

    // const [accountInfo, setAccountInfo] = useState("");
    // window.onbeforeunload = function() {
    //     localStorage.clear();
    //    return ""
    //   };
  const disconnectwallet = async () => {
    setAccountInfo("");
    localStorage.removeItem("accountInfo")
    if (localStorage.getItem("walletconnect") != null) {
        const provider = await UseProvider();
        await provider.disconnect();
    }
    window.location.reload();
}
    const sidetoggle = () =>{
        if(document.getElementById("dashboard").classList.contains("showsidebar")){
            document.getElementById("dashboard").classList.remove("showsidebar")
            document.getElementById("dashboard").classList.add("hidesidebar")
        }
        else if(document.getElementById("dashboard").classList.contains("hidesidebar")){
            document.getElementById("dashboard").classList.remove("hidesidebar")
            document.getElementById("dashboard").classList.add("showsidebar")

        }
    }
    const[wallet, setWallet] = useState(false)
  const [accountInfo, setAccountInfo] = useState("");
    const [userinfo , setUserinfo] = useState("");
  useEffect(()=>{
    setAccountInfo(getAccount());
    var user = cookies.get("admin-Datas")
    console.log("user" , user);
    setUserinfo(user);
  },[])

  const logOut = async () => {
    await logouthook();
    navigate("/adminlogin")
    window.location.reload();
}
    return(
        <>
      
            <div className='d-flex jc-between w-full'>
               <div className='d-flex align-items-center'>
               <div> <Button onClick={() => sidetoggle()}  variant="link" className='me-2 text-dark mobiletog ps-0'>
               <img src={bar}  className="menuopen"/>
                </Button></div>
                <div>
                <img src={ logo} className="adminlogo"/></div> </div>
               <div className='d-flex align-items-center'>
               <div className='header'>
         {wallet && <ConnectWallet onDismiss={()=> setWallet(false)}/>}
        <div className='d-flex jc-between'>
            {/* <button onClick={() => setWallet(true)} className=' btn btn-bord headerbtn nav-link m-4 webconnect'>Connect Wallet</button>   
            <button onClick={() => setWallet(true)} className=' btn btn-bord headerbtn nav-link m-4 mobconnect'>Connect</button>    */}
           {(accountInfo == "" || accountInfo == null) ?
              <button className='btn btn-bord headerbtn nav-link' onClick={() => setWallet(true)}>Connect Wallet</button>
              :
              <button  className=' btn btn-bord headerbtn nav-link' onClick={() => disconnectwallet()}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38,42)}`}</button> 
              
            }
           </div>
    </div>
               <Dropdown className="admin-prof">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                     Hi, Admin   <img src={userinfo?.userProfilePicturePath ? API_URL+userinfo?.userProfilePicturePath :prof} className="ms-3"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1" onClick={logOut}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
               </div>
                 
            </div>
        </>
    )
}

export default AdminHeader