export const ONEDAYINSECONDS = 86400;

export const BURN = "0x000000000000000000000000000000000000dEaD";

export const BLOCKS_PER_YEAR = 10512000;

// export const API_URL = "http://localhost:8097"

// export const API_URL = "http://43.204.99.101:8111"

//demo
export const API_URL = "https://irenademoapi.maticz.in"

 export const CHAINS = [

   {
        NAME: "BSC-TEST",
        RPC_URL:"https://data-seed-prebsc-1-s1.binance.org:8545/", // BSC TESTNET
        CHAIN_ID:97,
        // ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
        TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // 
        // MASTERCHEF: "0xcA491033CDCdE5407F5c7fCFC84209312d0AdBa4", // BSC NEW TESTNET
        IRENA : "0xe28e56fdF6303BE85b78bA8c71eBEC08221eaA8E"
    },
  //  {
  //       NAME: "AVALANCHE-TEST",
  //       RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc", // BSC TESTNET
  //       CHAIN_ID:43113,
  //       // ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //       TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // 
  //       // MASTERCHEF: "0xcA491033CDCdE5407F5c7fCFC84209312d0AdBa4", // BSC NEW TESTNET
  //       IRENA : "0x621F83B6c9D9a5924448356c8d507f4B34b57453"
  //   },

  //   {
  //     NAME: "Polygon-TEST",
  //     RPC_URL:"https://matic-mumbai.chainstacklabs.com",
  //     CHAIN_ID:80001, 
  //     TOKENADDRESS: "0x9433f3BaAE2984C6F5C8DE6989aB6dee23eaEE17",
  //      MASTERCHEF: "0x74962D8c90dCb1e4761DF94115BE449044179e5A", // new 
  //     // MASTERCHEF: "0x1afd408a544e901af55cB8735d8c6B49c47A11b2",//old
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
//   {
//     NAME: "BSC-MAINNET",
//     RPC_URL:"https://bsc-dataseed1.binance.org/",
//     CHAIN_ID:56,
//     TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781",
//     MASTERCHEF: "0x825A21c5A32d22b1241C7672EC9D04025960f766", 
//     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
// }

];


