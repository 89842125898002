import axios from "axios";
import Cookies from 'universal-cookie';
// import { param } from "../../../Backend/Routes/AdminRoute";
import { API_URL } from "../Config/env";
import * as api from "../Routes/Adminroutesfront";
const cookies = new Cookies();

const headers =  {
    'Content-Type': 'application/json',
    // 'Authorization': cookies.get('accessToken')?`Bearer ${cookies.get('accessToken')}`:((cookies.get('User-Datas'))?`Bearer ${(cookies.get('User-Datas')).token}`:"")
    'Authorization': cookies.get('admin-Datas') && `Bearer ${(cookies.get('admin-Datas'))?.refreshtoken}`
  }
  const headers1 = {
    'Content-Type': 'multipart/form-data',
    // 'Authorization': cookies.get('accessToken')?`Bearer ${cookies.get('accessToken')}`:((cookies.get('User-Datas'))?`Bearer ${(cookies.get('User-Datas')).token}`:"")
    'Authorization': cookies.get('admin-Datas') && `Bearer ${(cookies.get('admin-Datas'))?.refreshtoken}`
  };
export const loginhook = async(data) => {
    console.log("login data" , data);
    var formdata = new FormData();
    try{
      if(data){
        formdata.append("email" , data.email);
        formdata.append("password" , data.password);
      }
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + api.loginApi,
          'credentials': true,
          'headers': {
            'content-Type':'multipart/form-data',
          },
          data: formdata,
        });
        console.log("response",respdata);
        if(respdata?.data?.type == "success"){
            cookies.set('admin-email', respdata.data.record.email, { path: '/' }, {expires: new Date(Date.now()+600000) });
            cookies.set('admin-Datas',respdata.data.record, { path: '/' }, {expires: new Date(Date.now()+600000) })
          }
        return {
          data: respdata.data
        }
      }
      catch (err) {
        return {
          error: err
        }
      }
    }
    catch(e){
      console.log("error" , e);
    }
}

export const logouthook = async()=>{
  cookies.remove("admin-email");
  cookies.remove("admin-Datas");
  localStorage.removeItem("accountInfo");
}

export const getalluserhook = async(data)=> {
      console.log("data" , data);
    try {
      console.log("headers" , headers);
      let respData = await axios.get(API_URL+api.getuserApi,
        // {params: {userWalletAddress:data}}
        )
    //   let respData = await axios({
    //     'method': 'GET',
    //     'url': API_URL+api.getuserApi,
    //     headers : headers,
        
    // },{params :{userWalletAddress : data}});
      console.log("respdata",respData);
      return {
        data: respData
      }
    }
    catch (err) {
      return {
           error: err.response.data
      }
    }
  }

  export const getallshoeshook = async(data)=> {
    console.log("data" , data);
  try {
    console.log("headers" , headers);
    let respData = await axios.get(API_URL+api.getshoeApi,
      // {params: {userWalletAddress:data}}
      )
  //   let respData = await axios({
  //     'method': 'GET',
  //     'url': API_URL+api.getuserApi,
  //     headers : headers,
      
  // },{params :{userWalletAddress : data}});
    console.log("respdata",respData);
    return {
      data: respData
    }
  }
  catch (err) {
    return {
         error: err.response.data
    }
  }
}

export const blockuserhook = async(data) => {
  console.log("login data" , data);
  var formdata = new FormData();
  try{
    if(data){
      formdata.append("userWalletAddress" , data.userWalletAddress);
      formdata.append("walletaddress" , data?.walletaddress);
      formdata.append("userIsBlocked" , data.userIsBlocked);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.blockuserApi,
        'credentials': true,
        'headers': {
          'content-Type':'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response",respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch(e){
    console.log("error" , e);
  }
}

export const shoesettinghook = async(data) => {
  console.log("login data" , data);
  var formdata = new FormData();
  try{
    if(data){
      formdata.append("shoePid" , data.shoePid);
      formdata.append("shoeIsEnabled" , data.shoeIsEnabled);
      formdata.append("userWalletAddress" , data.userWalletAddress);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.shoesettingApi,
        'credentials': true,
        'headers': {
          'content-Type':'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response",respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch(e){
    console.log("error" , e);
  }
}

export const getalltokenhook = async(data)=> {
  console.log("data" , data);
try {
  console.log("headers" , headers);
  let respData = await axios.get(API_URL+api.gettokenApi,
    
    )

  console.log("respdata",respData);
  return {
    data: respData
  }
}
catch (err) {
  return {
       error: err.response.data
  }
}
}

export const updatetokenhook = async(data) => {
  console.log("update token data" , data);
  var formdata = new FormData();
  try{
    if(data){
      formdata.append("tokenType" , data.tokenType);
      formdata.append("tokenAddress" , data.tokenAddress);
      formdata.append("userWalletAddress" , data?.userWalletAddress);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.updatetokenApi,
        'credentials': true,
        'headers': {
          'content-Type':'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response",respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch(e){
    console.log("error" , e);
  }
}

export const addshoehook = async(data) => {
  console.log("update token data" , data);
  var formdata = new FormData();
  try{
    if(data){console.log("image" , data?.image);
      formdata.append("userWalletAddress" , data.userWalletAddress);
      formdata.append("shoeName" , data.shoeName);
      formdata.append("shoePid" , data?.shoePid);
      formdata.append("shoePrice" , data.shoePrice);
      formdata.append("shoePricePerMeter" , data.shoePricePerMeter);
      formdata.append("shoeDailyMeters" , data?.shoeDailyMeters);
      formdata.append("shoeTotalStock" , data.shoeTotalStock);
      formdata.append("shoeStockLeft" , data.shoeStockLeft);
      formdata.append("shoeUseDays" , data?.shoeUseDays);
      formdata.append("shoeStakeDays" , data?.shoeStakeDays);
      formdata.append("shoeMaxRewardPerDay" , data?.shoeMaxRewardPerDay);
      formdata.append("shoeTokensToBeStaked" , data?.shoeTokensToBeStaked);
      formdata.append("shoeIsEnabled" , data?.shoeIsEnabled);
      // data?.image?.map((item , i)=>formdata.append("image" , item));
      formdata.append("image1" , data?.image1);
      formdata.append("image2" , data?.image2);
      formdata.append("image3" , data?.image3);
      formdata.append("image4" , data?.image4);
      formdata.append("image5" , data?.image5);
      // formdata.append("luck" , data?.luck);
      // formdata.append("comfort" , data?.comfort);
      // formdata.append("efficiency" , data?.efficiency);
      // formdata.append("resilence" , data?.resilence);
      formdata.append("description" , data?.description);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + api.addshoeApi,
        'credentials': true,
        'headers': {
          'content-Type':'multipart/form-data'
          // 'content-Type': "application/json"
        },
        data: formdata,
      });
      console.log("response",respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch(e){
    console.log("error" , e);
  }
}
