import React,{useEffect, useState} from 'react';
import Cookies from 'universal-cookie';

import AdminHeader from './AdminHeader';
import Sidebar from './Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getalltokenhook } from '../../hooks/adminHook';
import { getAccount } from '../../hooks/useAccounts';
import { updatenativetoken, updaterewardtoken } from '../../hooks/useContract';
import { validatetoken } from '../../hooks/validation';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
const cookies = new Cookies();
const Dashboard = () =>{
    const location = useLocation();
    const navigate = useNavigate()
    useEffect(()=>{
        if(!cookies.get("admin-email")){
         navigate("/adminlogin");
         
        }
        
      },[])
    const [token , setToken] = useState([]);
    const [native , setNative] = useState();
    const [reward , setReward] = useState();
    const [error , setError] = useState();
    const [errors , setErrors] = useState();
    useEffect(()=>{
        if(!cookies.get("admin-email")){
            navigate("/adminlogin");
            
           }
        async function fetchdata(){
            const account = getAccount();
            if(account){
                await getalltokenhook(account).then((result)=>{
                    console.log("result" , result?.data?.data[0]?.tokenAddress);
                    result?.data?.data?.map((data)=>{
                        if(data?.tokenType ==  'Native')
                          setNative(data?.tokenAddress);
                        if(data?.tokenType == "Reward")
                           setReward(data?.tokenAddress);
                    })
                })
            }
           
        }
        fetchdata();
    } , [error , errors])
    const columns = [
        {
            name:"Id",
            reorder: true,
            cell: (data) =>(data.id)
        },
        {
            name:"Reservation Id",
            cell: (data) =>(data.reserveid)           
        },
        {
            name:"Room name",
            cell: (data) =>(data.Roomname)          
        },
        {
            name:"User Name",
            cell: (data) =>(data.username)
        },
        {
            name:"Email Id",
            cell: (data) =>(data.emailid)
        },
        {
            name:"User Date From",
            cell: (data) =>(data.userdatafrom)
        },
        {
            name:"User Date To",
            cell: (data) =>(data.userdatato)
        },
        {
            name:"Id Document",
            cell: (data) =>(data.iddocuments)
        },
        {
            name:"Status",
            cell: (data) =>(data.staus)
        }
    ]
    const data = [
        {
            id:1,
            reserveid:"1002",
            Roomname:"A Unique Studio",
            username:"David" ,
            emailid:"example@gmail.com",
            userdatafrom:"16.09.2022",
            userdatato:"22.09.2022",
            iddocuments:"file.pdf",
            staus:"approved"
        },
        {
            id:2,
            reserveid:"1002",
            Roomname:"A Unique Studio",
            username:"David" ,
            emailid:"example@gmail.com",
            userdatafrom:"16.09.2022",
            userdatato:"22.09.2022",
            iddocuments:"file.pdf",
            staus:"approved"
        },
        {
            id:3,
            reserveid:"1002",
            Roomname:"A Unique Studio",
            username:"David" ,
            emailid:"example@gmail.com",
            userdatafrom:"16.09.2022",
            userdatato:"22.09.2022",
            iddocuments:"file.pdf",
            staus:"approved"
        },
        {
            id:4,
            reserveid:"1002",
            Roomname:"A Unique Studio",
            username:"David" ,
            emailid:"example@gmail.com",
            userdatafrom:"16.09.2022",
            userdatato:"22.09.2022",
            iddocuments:"file.pdf",
            staus:"approved"
        }
     
    ]

    const handlenative = async()=>{
       const account = getAccount();
       
       const validate = await validatetoken(native);
       console.log("validatae" , validate);
            if(validate?.isValid){
                await updatenativetoken(native , account);
            }
            else{
                setError("Native token field is required !");
            }
    }

    const handlereward = async()=>{
        const account = getAccount();
        
        

        const validate = await validatetoken(reward);
       console.log("validatae" , validate);
            if(validate?.isValid){
                await updaterewardtoken(reward , account);
            }
            else{
                setErrors("Reward token field is required")
            }
        
     }
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

const datas = {
  labels: labels,
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "#4565b0",
      borderColor: "#4565b0",
      data: [0, 10, 5, 2, 20, 30, 45],
    },
  ],
};
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont p-sm-5 p-4'>
               <div className='d-flex jc-between'> 
              <h3 className='fw-600 '>Token</h3>
    
                
             </div> 
            <div className='container mt-2'>
                <div className='native_token row'>
                    <div className='col-lg-2 col-md-4 col-4'>
                    <h2 >Native Token</h2>
                    </div>
                    <div className='col-lg-3 col-md-4 col-4'>
                    <input type="text" value={native ? native : ""} onChange ={(e)=>{setNative(e?.target?.value)}} />
                    <span className="text-danger f-12 d-block text-left">{error&&error}</span>
                        </div>
                        <div className='col-lg-1 col-md-4 col-4'>
                        <button className='nativetoken_update' onClick={handlenative}>Update</button>
                            </div>
                    
                </div>
                <div className='native_token mt-4 row'>
                <div className='col-lg-2 col-md-4 col-4'>
                    <h2>Reward Token</h2>
                    </div>
                    <div className='col-lg-3 col-md-4 col-4'>
                    <input type="text" value={reward ? reward : ""} onChange ={(e)=>{setReward(e?.target?.value)}}/> 
                    <span className="text-danger f-12 d-block text-left">{errors&&errors}</span>
                    </div>
                    <div className='col-lg-1 col-md-4 col-4'>
                    <button className='nativetoken_update' onClick={handlereward}>Update</button>
                    </div>
                </div>
                </div>
               </div> 
            </div>
        </div>
    )
}

export default Dashboard