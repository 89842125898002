import React, {  useState ,useEffect} from "react";

import { Modal,Button, Form } from "react-bootstrap";
import wall1 from "../../Assets/Images/wall1.png"
import wall2 from "../../Assets/Images/wall2.png"
import wall3 from "../../Assets/Images/wall3.png"
import wall4 from "../../Assets/Images/wall4.png"
import { UseProvider, useWeb3 } from "../../hooks/useWeb3";
import { MetamaskWallet, WalletConnect, BinanceWallet } from '../../hooks/useWallet';
import { getAccount } from "../../hooks/useAccounts";
import { sleep } from "../../hooks/useContract";
import {style,iconTheme,position} from '../../hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';

const ConnectWallet = (props) =>{
   const[wallet, setWallet] = useState(true)
   const [accountInfo, setAccountInfo] = useState("");
   const [walletConnect, set_walletConnect] = useState("")

   useEffect(() => {
    setAccountInfo(getAccount);
    
}, [accountInfo]); 

const MetaMask = async () => {
    await MetamaskWallet()
    await sleep(1000)
    await useWeb3();
    setAccountInfo(localStorage.getItem("accountInfo"))
    if (localStorage.accountInfo) {

       
        toast.success("Wallet Connected successfully", {
            position:position.position,
            style:style,
            iconTheme: iconTheme,
        })
        setTimeout(() => {
            
            window.location.reload()
        }, 1000);
       
    }
}

const Binance = async () => {
    await BinanceWallet()
    await sleep(1000)
    await useWeb3();
    setAccountInfo(localStorage.getItem("accountInfo"))
    if (localStorage.accountInfo) {

        toast.success("Wallet Connected successfully", {
            position:position.position,
            style:style,
            iconTheme: iconTheme,
        })
        setTimeout(() => {
            
            window.location.reload()
        }, 1000);

       
    }

}

const enableWallet = async () => {
    const provider = await WalletConnect()
    set_walletConnect(provider)

    setAccountInfo(localStorage.getItem("accountInfo"))
   
    if (localStorage.getItem("accountInfo")) {
        setWallet(false)
        toast.success("Wallet Connected successfully", {
            position:position.position,
            style:style,
            iconTheme: iconTheme,
        })
       
        setTimeout(() => {
            
            window.location.reload()
        }, 1000);
    }

}

// window.ethereum.on('accountsChanged', async () => {
//     connectwallet();
// });
    return(
        <div className="">
                
            <Modal show={wallet} size="sm" centered className="wallmodal">
             
                <Modal.Body  className="p-0 br-15 walletmodal">
                <h5 className="themeclr fw-600">Connect wallet</h5>
                <hr />
                <button onClick={() => props.onDismiss()} className="close-btn"><span className='fa fa-times'></span></button>
                 <div className="p-4">
                   
               <div className="row mt-4 justify-content-center">
                <div className="col-6 mb-4">
                    <div className="shadowbox" onClick={()=>MetaMask()}>
                        <img src={wall1} />
                       <p>Metamask</p> 
                    </div>
                </div>
                <div className="col-6  mb-4">
                    <div className="shadowbox" onClick={enableWallet}>
                        <img src={wall3} />
                        <p>Wallet Connect</p>
                    </div>
                </div>
                <div className="col-6 text-center">
                    <div className="shadowbox" onClick={Binance}>
                        <img src={wall2} />
                       <p>Trust Wallet</p> 
                    </div>
                </div>
                {/* <div className="col-6">
                    <div className="shadowbox" onClick={MetaMask}>
                        <img src={wall4} />
                       <p>DApps</p> 
                    </div>
                </div> */}
               </div></div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default ConnectWallet