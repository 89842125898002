import React, { useEffect, useState } from 'react';
import sizeOf from "image-size"
import {Modal,Button, FloatingLabel, Form} from 'react-bootstrap'
import $ from "jquery"
import BigNumber from 'bignumber.js';
import Shoe from "../../../Assets/Images/shoe_if.png"
import { addshoehook, getallshoeshook } from '../../../hooks/adminHook';
import { getAccount } from '../../../hooks/useAccounts';
import { Addshoe, convertbignumber, gettokendecimal, UseIrena } from '../../../hooks/useContract';
import { validateaddshoe } from '../../../hooks/validation';
import toast from 'react-hot-toast';
const AddToken = (props) =>{
    const[token, setToken] = useState(true);

    const [name , setName] = useState("");
    const [price , setPrice] = useState();
    const [useday ,setUseday] = useState();
    const [stakedays , setStakedays] = useState();
    const [reward , setReward] = useState();
    const [tokentobestaked , setTokentobestaked] = useState();
    const [stock , setStock] = useState();
    const [image , setImage] = useState([]);
    const [imageview , setImageview] = useState([]);
    const [image1 , setImage1] = useState();
    const [image1view , setImage1view] = useState();
    const [image2 , setImage2] = useState();
    const [image2view , setImage2view] = useState();
    const [image3 , setImage3] = useState();
    const [image3view , setImage3view] = useState();
    const [image4 , setImage4] = useState();
    const [image4view , setImage4view] = useState();
    const [image5 , setImage5] = useState();
    const [image5view , setImage5view] = useState();
    const [luck , setLuck] = useState("");
    const [comfort , setComfort] = useState("");
    const [efficiency , setEfficiency] = useState("");
    const [resilence , setResilence] = useState("");
    const [description , setDescription] = useState("");
    
    const [dailykm , setDailykm] = useState();
    const [error , setError] = useState({});
    useEffect(()=>{
        async function fetchdata1(){
             if(!await window?.ethereum?._metamask.isUnlocked() || !localStorage.getItem("accountInfo") || !sessionStorage.getItem("accountInfo")){
               props.onDismiss();
             }
           }
           fetchdata1();
    })

    const handleadd = async()=>{
        let data = {
            shoename : name,
            useday : useday,
            price : price,
            stakedays : stakedays,
            reward : reward,
            tokentobestaked : tokentobestaked,
            stock : stock,
            dailykm : dailykm,
            image1 : image1,
            image2 : image2,
            image3 : image3,
            image4 : image4,
            image5 : image5,
            luck : luck,
            comfort : comfort,
            efficiency : efficiency,
            resilence : resilence,
            description : description
            // image : image  pid?.data?.data?.data?.length
        }
        let validate = await validateaddshoe(data);
        console.log("validate" , validate);//validate.isValid
        if(validate.isValid){
            const account = getAccount();
            const decimal = await gettokendecimal();
            console.log("addtoken decimal" , decimal);
        const shoeinfo =[];
        shoeinfo[0] = await convertbignumber(price , decimal?.reward);
        shoeinfo[1] =await convertbignumber(reward/1000 , decimal?.reward);
        shoeinfo[2] = parseInt(dailykm*1000);    //convertbignumber(dailykm*1000 , decimal?.native);
        shoeinfo[3] =parseInt(stock)
        shoeinfo[4] = parseInt(useday) //convertbignumber(useday , decimal?.native);
        shoeinfo[5] = parseInt(stakedays)   //convertbignumber(stakedays , decimal?.native)
        shoeinfo[6] =await convertbignumber(tokentobestaked , decimal?.native);
        const shoedata = await Addshoe(name , shoeinfo , account , 
            image1 , image2 , image3 , image4 , image5  , description);
        props.onDismiss();
        }
        else{
            setError(validate?.errors)
            if(validate?.errors?.image){
                toast.error(validate?.errors?.image);
            }
        }
        
        // console.log("shoedata" , shoedata);
        // const pid = await getallshoeshook(account);
        // let payload = {
        //         userWalletAddress : account,
        //         shoeName : shoedata?.shoeName, 
        //         shoePid : pid?.data?.data?.data?.length,
        //         shoePrice : shoedata?.shoePrice,
        //         shoePricePerKM : shoedata?.shoePricePerKM,
        //         shoeDailyKMs : shoedata?.shoeDailyKMs,
        //         shoeTotalStock : shoedata?.shoeTotalStock,
        //         shoeStockLeft : shoedata?.shoeStockLeft,
        //         shoeUseDays : shoedata?.shoeUseDays,
        //         shoeStakeDays : shoedata?.shoeStakeDays,
        //         shoeMaxRewardPerDay : shoedata?.rewardMaxPerDay,
        //         shoeTokensToBeStaked : shoedata?.shoeTokensToBeStaked,
        //         shoeIsEnabled : shoedata?.shoeIsEnabled,
        //         image : image
        // }
        // await addshoehook(payload);
    }
    return(
        <>
         
      <Modal show={token} centered>
       
        <Modal.Body>
            <div>
               
                    <button className='btn btn-theme close-btn' onClick={() => props.onDismiss()}><span className='fa fa-times'></span></button>
                
            <div className='br-10 h-55'>
                <div className='row add_tokens'>
                    <div className='col-lg-6 col-6 p-2 shoelisd'>
                      <div className='shoeeior'>
                      <img src={image1view ? image1view : Shoe} />
                      </div>
                       <input type="file" id = "img1" className='showselect'
                        // onChange ={(e)=>{
                        //     var img = []
                        //     console.log("e" , e?.target?.files);
                        //     // setImageview(URL.createObjectURL(e?.target?.files[0]));
                        //     for(var i=0;i<e?.target?.files?.length;i++){
                        //         imageview[i] = URL.createObjectURL(e?.target?.files[i])
                        //         // setImage(current => [...current, e?.target?.files[i]]);
                        //         img?.push(e?.target?.files[i]);
                        //     }
                            
                        //     setImage(img); 
                        // }}
                        onChange={
                            (e)=>{
                            // e?.target?.files[0]&&setImage1view(URL.createObjectURL(e?.target?.files[0]))
                            // e?.target?.files[0]&&setImage1(e?.target?.files[0]);
                            // var image = sizeOf(URL.createObjectURL(e?.target?.files[0]));
                            // var height = image?.height;
                            // var width = image?.width;
                            // alert("Current width=" + width + ", " + "Original height=" + height)
                            // var myImg = document.querySelector("#sky");
                            // var currWidth = myImg.clientWidth;
                            // var currHeight = myImg.clientHeight;
                            // alert("Current width=" + currWidth + ", " + "Original height=" + currHeight);
                            // $(document).on('change','#tt' , function(){
                            //     var img = document.getElementById('tt');
                            //     var nheight = img.clientHeight;
                            //     var nwidth = img.clientWidth;
                            //     alert(nheight)
                            //     alert(nwidth)
                            // });
                            var _URL = window.URL || window.webkitURL;
                            $(document).on('change','#img1',function() {
                                var file, img;
                                if ((file = this.files[0])) {
                                    img = new Image();
                                    img.onload = function() {
                                        // alert(this.width + " " + this.height);
                                        if(this.width <= 920 && this.height <= 920){
                                            console.log("image uploading");
                                            file&&setImage1view(URL.createObjectURL(file))
                                            file&&setImage1(file);
                                        }
                                        else{
                                            alert("Invalid image height and width");
                                        }
                                    };
                                    img.onerror = function() {
                                        alert( "not a valid file: " + file.type);
                                    };
                                    img.src = _URL.createObjectURL(file);
                                }
                            
                            });
                        }
                    }
                       />
                        <span className="text-danger f-12 d-block text-left">{error?.image}</span>
                    </div>
                    <div className='col-lg-6 col-6 p-2'>
                    <div className='row'>
                    <div className='col-lg-6 col-6 mb-2 shoelisd1'>
                    <div className='shoeeior1'>
                    <img src={image2view ? image2view : Shoe} />
                    </div>
                    <input type="file" id = "img2"  className='showselect'
                        onChange={(e)=>{
                            var _URL = window.URL || window.webkitURL;
                            $(document).on('change','#img2',function() {
                                var file, img;
                                if ((file = this.files[0])) {
                                    img = new Image();
                                    img.onload = function() {
                                        // alert(this.width + " " + this.height);
                                        if(this.width <= 920 && this.height <= 920){
                                            console.log("image uploading");
                                            file&&setImage2view(URL.createObjectURL(file))
                                            file&&setImage2(file);
                                        }
                                        else{
                                            alert("Invalid image height and width");
                                        }
                                    };
                                    img.onerror = function() {
                                        alert( "not a valid file: " + file.type);
                                    };
                                    img.src = _URL.createObjectURL(file);
                                }
                            
                            });
                        }}
                    />
                        </div>
                    <div className='col-lg-6 col-6 mb-2 shoelisd2'>
                    <div className='shoeeior1'>
                    <img src={image3view ? image3view : Shoe} />
                    </div>
                    <input type="file" id = "img3"  className='showselect' 
                        onChange={(e)=>{
                            var _URL = window.URL || window.webkitURL;
                            $(document).on('change','#img3',function() {
                                var file, img;
                                if ((file = this.files[0])) {
                                    img = new Image();
                                    img.onload = function() {
                                        // alert(this.width + " " + this.height);
                                        if(this.width <= 920 && this.height <= 920){
                                            console.log("image uploading");
                                            file&&setImage3view(URL.createObjectURL(file))
                                            file&&setImage3(file);
                                        }
                                        else{
                                            alert("Invalid image height and width");
                                        }
                                    };
                                    img.onerror = function() {
                                        alert( "not a valid file: " + file.type);
                                    };
                                    img.src = _URL.createObjectURL(file);
                                }
                            
                            });
                        }}
                    />
                        </div>
                    <div className='col-lg-6 col-6 mb-2 shoelisd3'>
                    <div className='shoeeior1'>
                    <img src={image4view ? image4view : Shoe}  />
                    </div>
                    <input type="file" id = "img4"  className='showselect'
                         onChange={(e)=>{
                            var _URL = window.URL || window.webkitURL;
                            $(document).on('change','#img4',function() {
                                var file, img;
                                if ((file = this.files[0])) {
                                    img = new Image();
                                    img.onload = function() {
                                        // alert(this.width + " " + this.height);
                                        if(this.width <= 920 && this.height <= 920){
                                            console.log("image uploading");
                                            file&&setImage4view(URL.createObjectURL(file))
                                            file&&setImage4(file);
                                        }
                                        else{
                                            alert("Invalid image height and width");
                                        }
                                    };
                                    img.onerror = function() {
                                        alert( "not a valid file: " + file.type);
                                    };
                                    img.src = _URL.createObjectURL(file);
                                }
                            
                            });
                        }}
                    />
                        </div>
                    <div className='col-lg-6 col-6 mb-2 shoelisd4'>
                    <div className='shoeeior1'>
                    <img src={image5view ? image5view : Shoe} />
                    </div>
                    <input type="file" id ="img5" className='showselect'
                         onChange={(e)=>{
                            var _URL = window.URL || window.webkitURL;
                            $(document).on('change','#img5',function() {
                                var file, img;
                                if ((file = this.files[0])) {
                                    img = new Image();
                                    img.onload = function() {
                                        // alert(this.width + " " + this.height);
                                        if(this.width <= 920 && this.height <= 920){
                                            console.log("image uploading");
                                            file&&setImage5view(URL.createObjectURL(file))
                                            file&&setImage5(file);
                                        }
                                        else{
                                            alert("Invalid image height and width");
                                        }
                                    };
                                    img.onerror = function() {
                                        alert( "not a valid file: " + file.type);
                                    };
                                    img.src = _URL.createObjectURL(file);
                                }
                            
                            });
                        }}
                    />
                        </div>
                    </div>
                    </div>
                </div>
                <div className='row showinputt'>
                    <div className='col-lg-6 col-12  mb-4'>
                        <h2 className='tokenh2'>Shoe Name</h2>
                    <input type="text" className='form-control ' onChange={(e)=>{setName(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.shoename}</span>
                    </div>
                    <div className='col-lg-6 col-12 mb-4'>
                        <h2 className='tokenh2'>Shoe Use Day</h2>
                    <input type="text" className='form-control'onChange={(e)=>{setUseday(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.useday}</span>
                    </div>
                    <div className='col-lg-6 col-12 mb-4'>
                        <h2 className='tokenh2'>Shoe Price</h2>
                    <input type="text" className='form-control'onChange={(e)=>{setPrice(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.price}</span>
                    </div>
                    <div className='col-lg-6 col-12 mb-4'>
                        <h2 className='tokenh2'>Stake Days</h2>
                    <input type="text" className='form-control mb-0' onChange={(e)=>{setStakedays(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.stakedays}</span>
                    </div>
                    <div className='col-lg-6 col-12 mb-4'>
                        <h2 className='tokenh2'>Rewards / km</h2>
                    <input type="text" className='form-control mb-0' onChange={(e)=>{setReward(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.reward}</span>
                    </div>
                    <div className='col-lg-6 col-12 mb-4'>
                        <h2 className='tokenh2'>Tokens to be Staked</h2>
                    <input type="text" className='form-control mb-0' onChange={(e)=>{setTokentobestaked(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.tokentobestaked}</span>
                    </div>
                    <div className='col-lg-6 col-12 mb-4'>
                        <h2 className='tokenh2'>Total Stock</h2>
                    <input type="text" className='form-control mb-0'onChange={(e)=>{setStock(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.stock}</span>
                    </div>
                    <div className='col-lg-6 col-12 mb-4'>
                        <h2 className='tokenh2'>Daily Km</h2>
                    <input type="text" className='form-control mb-0'onChange={(e)=>{setDailykm(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.dailykm}</span>
                    </div>
                    {/* <div className='col-lg-6 col-12  mb-4'>
                        <h2 className='tokenh2'>Shoe Luck</h2>
                    <input type="text" className='form-control ' onChange={(e)=>{setLuck(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.luck}</span>
                    </div>
                    <div className='col-lg-6 col-12  mb-4'>
                        <h2 className='tokenh2'>Shoe Comfort</h2>
                    <input type="text" className='form-control ' onChange={(e)=>{setComfort(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.comfort}</span>
                    </div>
                    <div className='col-lg-6 col-12  mb-4'>
                        <h2 className='tokenh2'>Shoe Efficiency</h2>
                    <input type="text" className='form-control ' onChange={(e)=>{setEfficiency(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.efficiency}</span>
                    </div>
                    <div className='col-lg-6 col-12  mb-4'>
                        <h2 className='tokenh2'>Shoe Resilence</h2>
                    <input type="text" className='form-control ' onChange={(e)=>{setResilence(e?.target?.value)}}/>
                    <span className="text-danger f-12 d-block text-left">{error?.resilence}</span>
                    </div> */}
                    <div className=' col-12  mb-4'>
                        <h2 className='tokenh2'>Shoe Description</h2>
                        <textarea className='form-control' rows="4" style={{borderRadius: 5, width:"100%"}} onChange={(e)=>{setDescription(e?.target?.value)}}></textarea>
               
                    <span className="text-danger f-12 d-block text-left">{error?.description}</span>
                    </div>
                </div>
             

                           
<div className='text-center mt-2'>
       <Button className='btn btn-theme aquabtn ' onClick={handleadd}>
            Add
          </Button>
</div>
                         
            </div>
            </div>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default AddToken