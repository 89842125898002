import { API_URL } from "../Config/env";
const BASE_URL = "/admin";

export const loginApi = BASE_URL + "/log-in";

export const getuserApi = BASE_URL + "/Get-all-users";

export const getshoeApi = BASE_URL + "/Get-all-shoes-admin";

export const blockuserApi = BASE_URL + "/User-settings";

export const shoesettingApi = BASE_URL + "/Shoe-settings"; 

export const gettokenApi = BASE_URL + "/Get-token-info";

export const updatetokenApi = BASE_URL + "/Update-token";

export const addshoeApi = BASE_URL + "/Add-shoe";