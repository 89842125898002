import { MASTERCHEF_ABI } from "../Config/masterchefAbi";
import { ERC20_ABI } from "../Config/erc20";
import toast, { Toaster } from 'react-hot-toast';
import { GetChainIndex, useWeb3 ,usehttpProvider} from "./useWeb3"
import { CHAINS } from "../Config/env";
import BigNumber from 'bignumber.js';
import {style,iconTheme,position} from '../hooks/useToast'
import { getAccount } from "./useAccounts";
import axios from "axios";
import Web3 from "web3"
import { IRENA_ABI, IRENA_ADDRESS } from "../Config/irenacontract";
import { addshoehook, blockuserhook, getallshoeshook, getalltokenhook, shoesettinghook, updatetokenhook } from "./adminHook";
import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();
export const UseIrena = async()=>{
    try{
    const web3 = await useWeb3();
    console.log("abi" , IRENA_ABI , getIrenaAddress());
    const contract = new web3.eth.Contract(IRENA_ABI, getIrenaAddress());
   
    return contract;
}catch (e) {
     console.log("error" , e);          
}  
}

export const UseERC20 = async (VALUE) => {
    try{
    const web3 = await useWeb3();
    // const web3 = await usehttpProvider();
    const Tokencontract = new web3.eth.Contract(ERC20_ABI, VALUE);
    return Tokencontract;
}catch (e) {
               
}  
}

export const  sleep = (ms) => 
{
    new Promise(resolve => setTimeout(resolve, ms))
}
export const getIrenaAddress = ()=>{
    try{
    const index = GetChainIndex();
    const ContractAddress = CHAINS[index].IRENA;
 console.log("ContractAddress",ContractAddress);
    return ContractAddress;
}catch (e) {
               
}
}
export const approveContract = async(account,token)=>{
   
    const erc20Contract = await UseERC20(token);
    const data = erc20Contract.methods.approve(getIrenaAddress(),"115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account})
    await toast.promise(data, {
     loading: 'Approving...',
     success: 'Approved Successfully',
     error: 'Try Again',
     }, {
     style: {
     minWidth: '300px',
     minHeight: '55px'
     }
     })
}


export const getTotalproposals = async () => {
    try{
    const stakecontract = await UseIrena();
   
    const proposal = await stakecontract.methods.getTotalProposals().call()
   
    return proposal;
}catch (e) {
               
}}

export const getSingleproposals = async (voteid) => {
    try{
    const stakecontract = await UseIrena();
   
    const proposal = await stakecontract.methods._proposals(voteid).call()
    return proposal;
}catch (e) {
               
}}



export const CreateProposals = async(info,account) =>{
    var tokeninfo = await UseTokenInfo();
    var userbalance = await UserBalance(account)
    
    if(tokeninfo.ProposingRight > (userbalance / 10 ** Number(tokeninfo.decimals))){
       

        toast.error(`Insufficient Token Balance`,{
                position:position.position,
                style:style,
                iconTheme: iconTheme,
            })

    }else{

   
    const stakecontract = await UseIrena();
    const data = stakecontract.methods.createProposal(info).send({ from: account})
    await toast.promise(data, {
        loading: 'Creating New Proposal ...',
        success: 'Proposal Created Successfully',
        // error: 'Try Again',
    }, { position:position.position,
    style:style,
    iconTheme: iconTheme,
    }
    );
}
}

export const NewVote = async(proposalid,vote,account) =>{
    var tokeninfo = await UseTokenInfo();
    var userbalance = await UserBalance(account)
    
    if(tokeninfo.votingRight > (userbalance / 10 ** Number(tokeninfo.decimals))){
       

        toast.error(`Insufficient Token Balance`,{
                position:position.position,
                style:style,
                iconTheme: iconTheme,
            })

    }else{ const stakecontract = await UseIrena();
    const data = stakecontract.methods.vote(proposalid,vote).send({ from: account})
    await toast.promise(data, {
        loading: 'Creating Your Vote...',
        success: 'Voted Successfully',
        error: 'Try Again',
    }, { position:position.position,
    style:style,
    iconTheme: iconTheme,
    }
    );}
    
   
}

export const Claim = async(account) =>{
    
    const stakecontract = await UseIrena();
    const data = stakecontract.methods.claim().send({ from: account})
    await toast.promise(data, {
        loading: 'Requesting for Claim Tokens...',
        success: 'Tokens Claimed Successfully',
        error: 'Error ! When Claiming Token',
    }, { position:position.position,
    style:style,
    iconTheme: iconTheme,
    }
    );
}


export const toFixedNumber = (x)=> {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}


export const UseTokenInfo = async () => {
    try{
        const stakecontract = await UseIrena();
   
    const decimals= await stakecontract.methods._decimals().call();
    const totalsupply= await stakecontract.methods.totalSupply().call();
    const profitpershare= await stakecontract.methods.PROFIT_PER_SHARE().call();
    const votingRight = await stakecontract.methods.VOTING_RIGHTS().call();
    const ProposingRight = await stakecontract.methods.PROPOSING_RIGHTS().call();
    console.log("decimals" , decimals);
    const data = {
          decimals: decimals,
          totalsupply:totalsupply,
          profitpershare:profitpershare,
          votingRight:votingRight,
          ProposingRight:ProposingRight
         }
   
    return data;
}catch (e) {
               
}
}


export const transaction_list = async () => {
    
    var data = await UseTokenInfo()
    const presaleAddress = UseIrena();
    var apikey = "AYZA1MFT5RMKK8XE2525P9MC45NV5WUYT6";
    var address = getAccount();
    console.log("address",address);
    var contractaddress = presaleAddress;
    var Decimal_contract = data.decimals;
    var zeros = "1e" + Decimal_contract; 
    var Decimal_contract = 1 * zeros;
    var header = { "Content-Type": "application/json" };
    var bsc_api = "https://api-testnet.bscscan.com/"; // Change Api when move to live
    var address = getAccount();
      var options = {
      url: bsc_api +
      "api?module=account&action=txlist&address=" + address +"&contractaddress=" +contractaddress +"&apikey=" +apikey,
        method: "GET",
        headers: header,
      };
      var eth_decimal = Decimal_contract;
    
let transactions_Arr =[]
await axios.get(options.url).then(data => {
 
const Response = data.data.result;
    
transactions_Arr = [];
      for (let item of Response.reverse()) {
        let txid = item.hash;
        var amount = item.value / eth_decimal;
        var toaddress = item.to;

        var fromaddress = item.from;

        var timestamp = item.timeStamp;

        transactions_Arr.push({
          fromaddress: fromaddress,
          toaddress: toaddress,
          amount: amount,
          txid: txid,
          timestamp: timestamp,
        });
      }
      // console.log("transactions_Arr",transactions_Arr);
  })
  return transactions_Arr;
    
    
  } 


export const UserBalance = async (account) => {
    try{
    const stakecontract = await UseIrena();
   
    const balance = await stakecontract.methods.balanceOf(account).call()
    return balance;
}catch (e) {
               
}}


export const UserPendingProfit = async (account) => {
    try{
    const stakecontract = await UseIrena();
   
    const profit = await stakecontract.methods.pendingProfit(account).call()
    return profit;
}catch (e) {
               
}}

export const UserUSDPrice = async(value) => {
   
    let usdvalue = 0;
      if(Number(value) > 0){
             await axios.get("https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD")
          .then (data => {
              usdvalue = Number(data.data.USD) * Number(value);
              
          })
          
    }
    return usdvalue;
 }


 export const searchedValue = async(ProposalList,searchSymbol) => {
     const filteredData  = await ProposalList.filter(value => ((value.isActive == searchSymbol)));
     return filteredData;
}

export const PendingData = async(ProposalList) => {
    const filteredData  = await ProposalList.filter(value => (( !value.isActive && value.status)));
    return filteredData;
}
export const rejectedData = async(ProposalList,searchSymbol) => {
    const filteredData  = await ProposalList.filter(value => (( !value.isActive == searchSymbol)));
    return filteredData;
}


//IRENA

export const userblock = async(address,account , status) =>{
    // var tokeninfo = await UseTokenInfo();
    // var userbalance = await UserBalance(account)
    const stakecontract = await UseIrena();
    console.log("stakecontra", stakecontract);
    const data =   stakecontract.methods.userSettings(address).send({ from: account});
    console.log("data1" , data);
    await toast.promise(data, {
        loading: status ? 'Creating Unblock User' : 'Creating Block User ...',
        success: status ?'Unblocked Successfully' : 'Blocked Successfully',
        error: 'Try Again',
    }, { position:position.position,
    style:style,
    iconTheme: iconTheme,
    }
    );
    await sleep(1000)
    const userdata = await stakecontract.methods.userDetails(address).call();

    console.log("data" , userdata );
    let payload = {
        userWalletAddress : account,
        walletaddress : address,
        userIsBlocked : userdata?.userIsBlocked
    }
    console.log("payload" , payload);
    const response = await blockuserhook(payload);
   
    window.location.reload();
}

export const updatenativetoken = async(address,account) =>{
    // var tokeninfo = await UseTokenInfo();
    // var userbalance = await UserBalance(account)
    console.log("address" , address, account);
    const stakecontract = await UseIrena();
    console.log("stakecontract", stakecontract);
    const data =    stakecontract.methods.setIrenaTokenAddress(address).send({ from: account})
    await toast.promise(data, {
        loading: 'Updating Native Token ...',
        success: 'Updated Successfully',
        error: 'Try Again',
    }, { position:position.position,
    style:style,
    iconTheme: iconTheme,
    }
    );
    await sleep(1000)
    const res =  await stakecontract.methods.irena().call();
    console.log("data" , res);
    let payload = {
        tokenType : "Native",
        tokenAddress : res,
        userWalletAddress : account
    }
    const result = await updatetokenhook(payload);
    
    window.location.reload();
}

export const updaterewardtoken = async(address,account) =>{
    // var tokeninfo = await UseTokenInfo();
    // var userbalance = await UserBalance(account)
    const stakecontract = await UseIrena();
    console.log("stakecontract", stakecontract);
    const data =   stakecontract.methods.setUsdtTokenAddress(address).send({ from: account})
    await toast.promise(data, {
        loading: 'Updating Reward Token ...',
        success: 'Updated Successfully',
        error: 'Try Again',
    }, { position:position.position,
    style:style,
    iconTheme: iconTheme,
    }
    );
    await sleep(1000)
    console.log("data" , data);
    const res =   await stakecontract.methods.usdt().call();
    console.log("data" , res);
    let payload = {
        tokenType : "Reward",
        tokenAddress : res,
        userWalletAddress : account
    }
    const result = await updatetokenhook(payload);
    
    window.location.reload();
}

export const Addshoe = async(name , shoeinfo,account , image1 , 
    image2 , image3 , image4 , image5  , description) =>{
    console.log("details" , name , shoeinfo);
    const stakecontract = await UseIrena();
    console.log("stakecontract", stakecontract );
    const pid = await getallshoeshook(account);
    console.log("pid" , pid?.data?.data?.data?.length);
    
    const data =  await stakecontract.methods.addShoe(shoeinfo , name , pid?.data?.data?.data?.length).send({ from: account})
    console.log("data" , data);
    await toast.promise(data, {
        loading: 'Creating new shoe ...',
        success: 'Created Successfully',
        error: 'Try Again',
    }, { position:position.position,
    style:style,
    iconTheme: iconTheme,
    }
    );
    await sleep(1000);
    const shoedata = await stakecontract.methods.getShoeInfo(pid?.data?.data?.data?.length).call();
    // return shoedata;
    console.log("shoedata" , shoedata);
    let payload = {
            userWalletAddress : account,
            shoeName : shoedata?.shoeName, 
            shoePid : pid?.data?.data?.data?.length,
            shoePrice : shoedata?.shoePrice,
            shoePricePerMeter : shoedata?.shoePricePerMeter,
            shoeDailyMeters : shoedata?.shoeDailyMeters,
            shoeTotalStock : shoedata?.shoeTotalStock,
            shoeStockLeft : shoedata?.shoeStockLeft,
            shoeUseDays : shoedata?.shoeUseDays,
            shoeStakeDays : shoedata?.shoeStakeDays,
            shoeMaxRewardPerDay : shoedata?.rewardMaxPerDay,
            shoeTokensToBeStaked : shoedata?.shoeTokensToBeStaked,
            shoeIsEnabled : shoedata?.shoeIsEnabled,
            image1 : image1,
            image2 : image2,
            image3 : image3,
            image4 : image4,
            image5 : image5,
            // luck : luck,
            // comfort : comfort,
            // efficiency : efficiency,
            // resilence : resilence,
            description : description
    }
    await addshoehook(payload);
    

    window.location.reload();
}

export const shoesettings = async(shoepid,account) =>{
    const stakecontract = await UseIrena();
    console.log("stakecontract", stakecontract);
    const data =   stakecontract.methods.shoeSettings(shoepid).send({ from: account});
    await toast.promise(data, {
        loading: 'Updating Shoe Setting ...',
        success: 'Updated Successfully',
        error: 'Try Again',
    }, { position:position.position,
    style:style,
    iconTheme: iconTheme,
    }
    );
    await sleep(1000)
   
    const shoedata = await stakecontract.methods.getShoeInfo(shoepid).call();
    console.log("data" , shoedata , data);
    let payload = {
        shoeIsEnabled : shoedata?.shoeIsEnabled,
        shoePid : shoepid,
        userWalletAddress : account
    }
    let result = await shoesettinghook(payload);
     window.location.reload();
}

export const gettokendecimal = async()=>{
    const account = getAccount();
    const tokendecimal = await getalltokenhook();
    console.log("token decimal" , tokendecimal);
    const native = tokendecimal?.data?.data?.Native?.tokenDecimals;
    const reward = tokendecimal?.data?.data?.Reward?.tokenDecimals;
    console.log("native , reward" , native , reward)
    // const stakecontract = await UseIrena();
    // console.log("stakecontract" , stakecontract);

    // const data =   await stakecontract.methods._decimals().call();
    // console.log("decimal" , data);
    return {
        native : native,
        reward : reward
    };
}


export const convertbignumber = async(value , decimal)=>{
    console.log("val , dec" , decimal , value);
    // const decimals = decimal
    const web3 = new Web3(window.ethereum)
    var data ;
    // (decimal == 18) ?
        data = await web3.utils.toWei(value.toString())
    //  : data = (new BigNumber(value).times(new BigNumber(10).pow(decimal)));
    // const data = (value*10**decimal).toString();
    
    console.log("converted" , data );
    return data.toString();
}


