

// app.js

import './App.css';
import './Assets/css/adminstyle.css'
import { BrowserRouter,Routes,Route  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import toast, { Toaster } from 'react-hot-toast';
import Dashboard from '../src/Components/Admin/Dashboard';
import TableView from '../src/Components/Admin/TableView';
import AdminLogin from '../src/Components/Admin/AdminLogin';
import User from '../src/Components/Admin/user';
import { useEffect, useState } from 'react';
const cookies = new Cookies();
function App() {
  const [login , setLogin] = useState(false);

  useEffect(()=>{
    cookies.get("admin-email")?setLogin(true) : setLogin(false);
  },[])
  return (
    <div className="App">
       <div><Toaster /></div> 
       <BrowserRouter >
       <Routes>
       <Route path="/adminlogin" element={<AdminLogin />} />
       {/* <Route path="/"  element={<Dashboard /> } /> */}
       <Route path='/shoe' element={<TableView />} />
       <Route path="/" element={<User />} />
       {/* {!login && <Route path="/adminlogin" element={<AdminLogin />} />}
           {login&&<Route path="/"  element={<Dashboard /> } />  }
          {login&&<Route path='/shoe' element={<TableView />} />}
          
          {login&&<Route path="/user" element={<User />} />} */}
       </Routes>
       </BrowserRouter>
    </div>
  );
}

export default App;
