import React,{useState , Fragment, useEffect} from 'react';
import Cookies from 'universal-cookie';
import AdminHeader from './AdminHeader';
import Sidebar from './Sidebar';
import Connectwallet from "../Modals/ConnectWallet";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import asset1 from '../../Assets/Images/asset1.png'
import asset2 from '../../Assets/Images/asset2.png'
import asset3 from '../../Assets/Images/asset3.png'
import { Button } from 'react-bootstrap';
import AddToken from '../Admin/Modal/AddToken';
import { getalluserhook } from '../../hooks/adminHook';
import { API_URL } from '../../Config/env';
import { getAccount } from '../../hooks/useAccounts';
import { userblock } from '../../hooks/useContract';
import { useNavigate } from 'react-router-dom';
const cookies = new Cookies();

const TableView = () =>{
  const navigate = useNavigate();
    const [theme, setTheme] = useState(false);
    const [user , setUser] = useState([]);
  const [walletmodal , setWalletmodal] = useState(false)
    // useEffect(()=>{
    //   if(!cookies.get("admin-email")){
    //    navigate("/adminlogin");
       
    //   }
    // },[])
    useEffect(()=>{
      if(!cookies.get("admin-email") ){
        navigate("/adminlogin");}
      async function fetchdata(){
        if(localStorage.getItem("accountInfo")){
          var address = await localStorage.getItem("accountInfo");
        console.log("address" , address);
        await getalluserhook().then((result)=>{
          console.log("result" , result);
           result?.data?.data?.data?.map((data , i)=>data.id = i+1)
          setUser(result?.data?.data?.data)
        })
        }
      }
      async function fetchdata1(){
    
        console.log("unlocked",await window?.ethereum?._metamask.isUnlocked())
     
         if(!await window?.ethereum?._metamask.isUnlocked() || !localStorage.getItem("accountInfo") || !sessionStorage.getItem("accountInfo")){
           setWalletmodal(true)
         }
       }
      fetchdata();
      fetchdata1();
    } , [])

    const handleblock = async(data , status)=>{
      const account = await getAccount();
      await userblock(data , account , status).then((result)=>{
        console.log("results" , result?.data?.data?.status);
      })
    }
    const columns = [
        {
          name: "No",
           selector: (vals) => vals?.id,
          
        },
     
          {
            name: "User Profile",
            selector: (vals) => <div className="img_table_div"><img src= {API_URL+vals?.userProfilePicturePath}></img></div>,
          
          },
          {
            name: "User Name",
            selector: (vals) => vals?.userName,
          
          },
          {
            name: "Wallet Address",
            selector: (vals) => `${vals.userWalletAddress?.substring(0, 5)}...${vals.userWalletAddress?.substring(38,42)}`,
        
          },
          {
            name: "Earned Rewards",
            selector: (vals) => vals.userRewardEarned
            ,
            
          }
          // {
          //   name: "Block",
          //   selector: (vals) =><i className={vals.userIsBlocked ? "fas fa-eye-slash" : "fas fa-eye"} onClick={() => handleblock(vals?.userWalletAddress , vals.userIsBlocked)}></i>,
          
          // }
          
       
    ]  
    const data = [
  
      {
        time: "1",
        token: "Peter John",
        count: <img src={asset1} />,
        img:"5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
       
      },
      {
        time: "2",
        token: "Peter John",
        count: <img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
      {
        time: "3",
        token: "Peter John",
        count: <img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
      {
        time: "4",
        token: "Peter John",
        count:<img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
       
      },
      {
        time: "5",
        token: "Peter John",
        count: <img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
      {
        time: "6",
        token: "Peter John",
        count: <img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
    
      {
        time: "7",
        token: "Peter John",
        count: <img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
       
      },
      {
        time: "8",
        token: "Peter John",
        count: <img src={asset1} />,
        img:"5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
      {
        time: "9",
        token: "Peter John",
        count:<img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
    
      {
        time: "10",
        token: "Peter John",
        count: <img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
       
      },
      {
        time: "12",
        token: "Peter John",
        count:<img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
      {
        time: "13",
        token: "Peter John",
        count:<img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
    
      {
        time: "14",
        token: "Peter John",
        count:<img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
       
      },
      {
        time: "15",
        token: "Peter John",
        count: <img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
      {
        time: "16",
        token: "Peter John",
        count: <img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
    
      {
        time: "17",
        token: "Peter John",
        count: <img src={asset1} />,
        img:"5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
       
      },
      {
        time: "18",
        token: "Peter John",
        count: <img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
      {
        time: "19",
        token: "Peter John",
        count: <img src={asset1} />,
        img: "5165fs36ez56z4x89D4as1",
        btn: "$500",
        block: <i className={theme ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => setTheme(!theme)}></i>,
      },
    
    
    ]
  
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];
const[token, setToken] = useState(false)


    return(
        <div id="dashboard" className='tableview showsidebar'>
            {token && <AddToken onDismiss={() => setToken(false)}/> }
            <div id='sidebar'>
                <Sidebar/>
                {walletmodal && <Connectwallet  onDismiss={()=>setWalletmodal(false)} /> }
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont p-sm-5 p-4'>
              <div className='d-flex jc-between'> 
              <h3 className='fw-600 '>Users</h3>
                
             </div> 
              
                <div className='tablesec  '>
                    <div className='mt-2 responsive resptable' >
                    <DataTable responsive={true} pagination
                        data={user}
                        columns={columns}
                    />
                    </div>
               </div>
               </div> 
            </div>
        </div>
    )
}

export default TableView