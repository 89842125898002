export const  style = {
      
                        
    minWidth: '300px',
    minHeight: '55px',
    border: '1px solid #1a3d4e',
    padding: '16px',
    color: '#1a3d4e'
    
}

export const iconTheme = {
    primary: '#15c6be',
    secondary: '#1a3d4e',
}

export const  position= {
    position: "top-center"
}