import React,{ useState }  from 'react';
import { Button } from 'react-bootstrap';
import logo from '../../Assets/Images/logo.svg'
import { NavLink } from 'react-router-dom';
import dash from '../../Assets/Images/Admin/dashboard.svg'
import setting from '../../Assets/Images/Admin/user.png'
import contact from '../../Assets/Images/Admin/tokenwhite.png'
import shoe from '../../Assets/Images/Admin/shoe.png'
import activesetting from '../../Assets/Images/Admin/useractive.png'
import activecontact from '../../Assets/Images/Admin/token.png'
import activeshoe from '../../Assets/Images/Admin/shoeactive.png'



const Sidebar = () =>{
    const toggleDrop = (e) =>{
        e.target.nextElementSibling.classList.toggle("d-none")
    }
    const sidetoggle = () =>{
        if(document.getElementById("dashboard").classList.contains("showsidebar")){
            document.getElementById("dashboard").classList.remove("showsidebar")
            document.getElementById("dashboard").classList.add("hidesidebar")
        }
        else if(document.getElementById("dashboard").classList.contains("hidesidebar")){
            document.getElementById("dashboard").classList.remove("hidesidebar")
            document.getElementById("dashboard").classList.add("showsidebar")
        }
    }
    const checkactive = (e) =>{
        if(!(e.target.classList.contains("active"))){
        e.target.parentNode.classList.add("active")
       } 
    } 
    return(
        <div className="sidebar">
            {/* <div className='text-center py-4'>
            <button className='togglebtn' onClick={() => sidetoggle()} ><img src={hamburgur} className="ham"/></button>
                <img src={logo} className="adminlogo"/>
                </div> */}
        
            
          
          <div className='sidelist'>
          
     
            {/* <div className='menus '>
               <NavLink to="/"  className="nounder">
                <img src={activecontact} className="active"/>
                <img src={contact} className="inactive"/>
                Token</NavLink>
                
            </div> */}
            <div className='menus' >
               <NavLink  activeClassName="active" to="/" className="nounder">
                <img src={activesetting} className="active" />
                <img src={setting} className="inactive"/>
                User</NavLink>
                
            </div>
            <div className='menus '>
               <NavLink to="/shoe"  className="nounder">
                <img src={activeshoe} className="active"/>
                <img src={shoe} className="inactive"/>
                Shoes</NavLink>
            </div>
         
          </div>
           




            <ul className='sidelist d-none'>
                <li>
                   <a>
                    <span>Dashboard</span></a>
                      
                        
                 <ul className='ps-0'>
                    <li>
                    <NavLink to="/admin/dashboard"  className="nounder">
                    <span>Dashboard</span></NavLink>
                    </li>
                 </ul>
                </li>
                <li  onClick={(e) => toggleDrop(e)}>
                  <a>  <img src={dash}/> 
                    <span>ta ble</span></a>
                
                <ul className='ps-0'>
                    <li>
                    <NavLink to="/admin/dashboard"  className="nounder">
                    <span>Dashboard</span></NavLink>
                    </li>
                 </ul>
                </li>
                <li><NavLink to="/admin/formview" className="nounder" ><img src={dash}/> <span>form</span></NavLink></li>
            </ul>
        </div>
    )
}

export default Sidebar