import Validator from "validator";
import isEmpty from "is-empty";
import sizeOf from "image-size"

export const validatelogin = (data) => {
    

    let errors = {};
    let isValid =true;
    if (Validator.isEmpty(data?.email)) {
        isValid = false;
        errors.email = "Email field is required";
        
    }
    else if(!Validator.isEmail(data?.email)){
        isValid = false;
        errors.email = "Invalid email"
    }
    if (Validator.isEmpty(data.password)) {
        isValid = false;
        errors.password = "Password field is required";
    }
   
return {
        errors,
        isValid: isEmpty(errors)
        
    };
};

export const validateaddshoe = async(data)=>{
    let errors = {};
    let isValid =true;
    if(Validator.isEmpty(data?.shoename)){
        isValid = false;
        errors.shoename = "Shoe name field is required"
    }
    if(!data?.useday?.match(/^\d+/)){
        isValid = false;
        errors.useday = "Please enter numeric character only! "
    }
    else if(!data?.useday%1 !=0){
        isValid = false;
        errors.useday = "Please enter numeric character only! "
    }
    if(!data?.price?.match(/^\d+/)){
        isValid = false;
        errors.price = "Please enter numeric character only! "
    }
    else if(!data?.price%1 !=0){
        isValid = false;
        errors.price = "Please enter numeric character only! "
    }

    if(!data?.stakedays?.match(/^\d+/)){
        isValid = false;
        errors.stakedays = "Please enter numeric character only! "
    }
    else if(!data?.stakedays%1 !=0){
        isValid = false;
        errors.stakedays = "Please enter numeric character only! "
    }

    if(!data?.reward?.match(/^\d+/)){
        isValid = false;
        errors.reward = "Please enter numeric character only! "
    }
    else if(!data?.reward%1 !=0){
        isValid = false;
        errors.reward = "Please enter numeric character only! "
    }

    if(!data?.tokentobestaked?.match(/^\d+/)){
        isValid = false;
        errors.tokentobestaked = "Please enter numeric character only! "
    }
    else if(!data?.tokentobestaked%1 !=0){
        isValid = false;
        errors.tokentobestaked = "Please enter numeric character only! "
    }

    if(!data?.stock?.match(/^\d+/)){
        isValid = false;
        errors.stock = "Please enter numeric character only! "
    }
    else if(!data?.stock%1 !=0){
        isValid = false;
        errors.stock = "Please enter numeric character only! "
    }

    if(!data?.dailykm?.match(/^\d+/)){
        isValid = false;
        errors.dailykm = "Please enter numeric character only! "
    }
    else if(!data?.dailykm%1 !=0){
        isValid = false;
        errors.dailykm = "Please enter numeric character only! "
    }
    if(!(data?.image1 && data?.image2 && data?.image3 && data?.image4 && data?.image5)){
        isValid = false;
        errors.image = "You must upload 5 images!"
    }
    else if(data?.image1){
            var result =  await filevalidate(data?.image1)
                if(!result){
                    isValid  = false;
                    errors.image = "Invalid file format"
                }
        }

    else if(data?.image2){
        var result =  await filevalidate(data?.image2)
                if(!result){
                    isValid  = false;
                    errors.image = "Invalid file format"
                }
}
else if(data?.image3){
    var result =  await filevalidate(data?.image3)
                if(!result){
                    isValid  = false;
                    errors.image = "Invalid file format"
                }
}
else if(data?.image4){
    var result =  await filevalidate(data?.image4)
    if(!result){
        isValid  = false;
        errors.image = "Invalid file format"
    }
}
else if(data?.image5){
    var result =  await filevalidate(data?.image5)
                if(!result){
                    isValid  = false;
                    errors.image = "Invalid file format"
                }
}
// if(1){console.log("luck")
//     var x = parseFloat(data?.luck)
//     if(Validator.isEmpty(data?.luck)){
//         isValid = false;
//         errors.luck = "Shoe Luck field is required"
//     }
//     if (isNaN(x) || x < 0 || x > 100) 
//   { 
//     // alert("value is out of range");
//     isValid  = false;
//     errors.luck = "Invalid percentage value!"
//   }
//   else
//   {
//      var decimalSeparator=".";
//      var val=""+x; 
//      if(val.indexOf(decimalSeparator)<val.length-3)
//      {
//         //alert("too much decimal");
//         isValid  = false;
//         errors.luck = "Too much decimal";
//      } 
//   } 
// }

// if(1){console.log("luck");
//     var x = parseFloat(data?.comfort)
//     if(Validator.isEmpty(data?.comfort)){
//         isValid = false;
//         errors.comfort = "Shoe Comfort field is required"
//     }
//     if (isNaN(x) || x < 0 || x > 100) 
//   { 
//     // alert("value is out of range");
//     isValid  = false;
//     errors.comfort = "Invalid percentage value!"
//   }
//   else
//   {
//      var decimalSeparator=".";
//      var val=""+x; 
//      if(val.indexOf(decimalSeparator)<val.length-3)
//      {
//         //alert("too much decimal");
//         isValid  = false;
//         errors.comfort = "Too much decimal";
//      } 
//   } 
// }

// if(1){console.log("luck")
//     var x = parseFloat(data?.efficiency)
//     if(Validator.isEmpty(data?.efficiency)){
//         isValid = false;
//         errors.efficiency = "Shoe Efficiency field is required"
//     }
//     if (isNaN(x) || x < 0 || x > 100) 
//   { 
//     // alert("value is out of range");
//     isValid  = false;
//     errors.efficiency = "Invalid percentage value!"
//   }
//   else
//   {
//      var decimalSeparator=".";
//      var val=""+x; 
//      if(val.indexOf(decimalSeparator)<val.length-3)
//      {
//         //alert("too much decimal");
//         isValid  = false;
//         errors.efficiency = "Too much decimal";
//      } 
//   } }

//   if(1){console.log("luck");
//     var x = parseFloat(data?.resilence)
//     if(Validator.isEmpty(data?.resilence)){
//         isValid = false;
//         errors.resilence = "Shoe Resilence field is required"
//     }
//     if (isNaN(x) || x < 0 || x > 100) 
//   { 
//     // alert("value is out of range");
//     isValid  = false;
//     errors.resilence = "Invalid percentage value!"
//   }
//   else
//   {
//      var decimalSeparator=".";
//      var val=""+x; 
//      if(val.indexOf(decimalSeparator)<val.length-3)
//      {
//         //alert("too much decimal");
//         isValid  = false;
//         errors.resilence = "Too much decimal";
//      } 
//   } }

  if(Validator.isEmpty(data?.description)){
    isValid = false;
    errors.description = "Description field is required"
}

    return {
        errors,
        isValid: isEmpty(errors)
        
    };
}

export const validatetoken = (data)=>{
    let errors = {};
    let isValid =true;
    if (Validator.isEmpty(data)) {
        isValid = false;
        errors.email = "Token field is required";
        
    }
    return {
        errors,
        isValid: isEmpty(errors)
        
    };
}

const filevalidate = (image)=>{
    var fileName = image?.name ? image?.name :image;
        var idxDot = fileName?.lastIndexOf(".") + 1;
        var extFile = fileName?.substr(idxDot, fileName.length).toLowerCase();
        if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp"){
            return true;
        }else{
            return false;
        }
        
  }

  export const validatefilesize = (img) => {
    console.log("img" , img);
    var image = URL.createObjectURL(img)
    // var image = sizeOf(img.type);
        var height = image?.height;
        var width = image?.width;
        console.log("height" , height , width);
        if(height <= 915 && width <= 915){
            return true;
        }
        else {
            return false;
        }
  }
