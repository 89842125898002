import React,{useState , Fragment, useEffect} from 'react';
import Cookies from 'universal-cookie';

import AdminHeader from './AdminHeader';
import Sidebar from './Sidebar';
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import asset1 from '../../Assets/Images/shoe.png'
import asset2 from '../../Assets/Images/asset2.png'
import asset3 from '../../Assets/Images/asset3.png'
import { Button } from 'react-bootstrap';
import AddToken from '../Admin/Modal/AddToken';
import ViewShoe from '../Admin/Modal/viewshoe';
import Connectwallet from "../Modals/ConnectWallet";
import { getallshoeshook } from '../../hooks/adminHook';
import { gettokendecimal, shoesettings } from '../../hooks/useContract';
import { getAccount } from '../../hooks/useAccounts';
import { API_URL } from '../../Config/env';
import { useNavigate } from 'react-router-dom';
const cookies = new Cookies();

const TableView = () =>{
  const navigate = useNavigate();
  const [shoes , setShoes] = useState([]);
  const [native , setNative] = useState();
  const [reward , setReward] = useState();
  const [walletmodal , setWalletmodal] = useState(false)
  const [singleentry , setSingleentry] = useState({});

  const [view , setView] = useState(false);
  useEffect(()=>{
    if(!cookies.get("admin-email")){
     navigate("/adminlogin");
    }

  },[])
  useEffect(()=>{
    if(!cookies.get("admin-email")){
      navigate("/adminlogin");
     }
    async function fetchdata(){
      const decimals = await gettokendecimal();
      console.log("decimals in table" , decimals);
      setNative(decimals?.native);
      setReward(decimals?.reward);
      var address = await localStorage.getItem("accountInfo");
      console.log("address" , address);
      if(address){
        await getallshoeshook(address).then((result)=>{
          console.log("result" , result?.data?.data?.data);
          result?.data?.data?.data?.map((data , i)=>data.id = i+1)
          setShoes(result?.data?.data?.data)
        })
      }
    }
    fetchdata();
  } , [])
  useEffect(()=>{
    if(!cookies.get("admin-email")){
      navigate("/adminlogin");
     }
    async function fetchdata(){
      
      var address = await localStorage.getItem("accountInfo");
      console.log("address" , address);
      if(address){
        await getallshoeshook(address).then((result)=>{
          console.log("result" , result?.data?.data?.data);
          result?.data?.data?.data?.map((data , i)=>data.id = i+1)
          setShoes(result?.data?.data?.data)
        })
      }
    }
    async function fetchdata1(){
    
      console.log("unlocked",await window?.ethereum?._metamask.isUnlocked())
   
       if(!await window?.ethereum?._metamask.isUnlocked() || !localStorage.getItem("accountInfo") || !sessionStorage.getItem("accountInfo")){
         setWalletmodal(true)
       }
     }
    fetchdata();
    fetchdata1();
  } , [])
    const columns = [
        {
          name: "No",
           selector: (vals) => vals?.id,
          
        },
     
          {
            name: "Shoes Profile",
            selector: (vals) => <div className="img_table_div"><img src ={ API_URL+vals?.imagepath +"/"+vals.image[0]}></img></div>,
          
          },
          {
            name: "Shoes Name",
            selector: (vals) => vals?.shoeName ,
            
          },
          {
            name: "Pricing",
            selector: (vals) => vals?.shoePrice/10**reward ,
           
          },
          {
            name: "Usage Days",
            selector: (vals) => ((vals?.shoeUseDays*1000) / (60*60*24*1000)) % 365,
          
          },
          {
            name: "Status",
            selector: (vals) => <input type="checkbox" checked={vals?.shoeIsEnabled} onChange={async()=>{
              const account = getAccount();
              await shoesettings(vals?.shoePid , account);
            }}/>
            ,
           
          },
          {
            name: "View",
            selector: (vals) => <button className='btn btn-theme aquabtn ' onClick={()=>{
              setView(true);
              setSingleentry(vals);
              console.log("singleentry" , vals);
            }}>View</button>
            // <input type="checkbox" checked={vals?.shoeIsEnabled} onChange={async()=>{
            //   const account = getAccount();
            //   await shoesettings(vals?.shoePid , account);
            // }}/>
            
           
          }
          
       
    ]  
    const data = [
  
      {
        time: "1",
        token: "Nike Shoes",
        count: <div className="img_table_div"><img src={asset1} /></div>,
        img: "$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
       
      },
      {
        time: "2",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
      },
      {
        time: "3",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
      },
      {
        time: "4",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
       
      },
      {
        time: "5",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img:"$5235",
        btn: "256 Days",
        status:<input type="checkbox" />,
      },
      {
        time: "6",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
      },
    
      {
        time: "7",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
      },
      {
        time: "8",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status:<input type="checkbox" />,
      },
      {
        time: "9",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status:<input type="checkbox" />,
      },
    
      {
        time: "10",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
      },
      {
        time: "11",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
      },
      {
        time: "12",
        token: "Nike Shoes",
        count:<img src={asset1} />,
        img:"$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
      },
    
      {
        time: "13",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
      },
      {
        time: "14",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img:"$5235",
        btn: "256 Days",
        status:<input type="checkbox" />,
      },
      {
        time: "15",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img:"$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
      },
    
      {
        time: "16",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img:"$5235",
        btn: "256 Days",
        status:<input type="checkbox" />,
      },
      {
        time: "17",
        token: "Nike Shoes",
        count:<img src={asset1} />,
        img: "$5235",
        btn: "256 Days",
        status:<input type="checkbox" />,
      },
      {
        time: "18",
        token: "Nike Shoes",
        count: <img src={asset1} />,
        img:"$5235",
        btn: "256 Days",
        status: <input type="checkbox" />,
      },
    
    
    ]
  
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];
const[token, setToken] = useState(false)


    return(
        <div id="dashboard" className='tableview showsidebar'>
            {token && <AddToken onDismiss={() => setToken(false)}/> }
            {view && <ViewShoe data = {singleentry} onDismiss = {()=> setView(false)}/>}
            <div id='sidebar'>
                <Sidebar/>
                {walletmodal && <Connectwallet  onDismiss={()=>setWalletmodal(false)} /> }
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont p-sm-5 p-4'>
              <div className='d-flex jc-between align-items-center'> 
              <h3 className='fw-600 '>Shoes</h3>  
              {/* <button className='btn btn-theme' onClick={() => setToken(true)}><i class="fas fa-plus"></i> Add Shoes</button> */}
              <button onClick={() => setToken(true)} className=' btn btn-bord headerbtn nav-link m-4'><i class="fas fa-plus"></i> Add Shoes</button>   
             </div> 
              
                <div className='tablesec'>
                    <div className='mt-2 responsive resptable table_view_shoes' >
                    <DataTable striped responsive={true} pagination
                        data={shoes}
                        columns={columns}
                    />
                    </div>
               </div>
               </div> 
            </div>
        </div>
    )
}

export default TableView