import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Button, InputGroup, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/Images/logo.png'
import { loginhook } from '../../hooks/adminHook';
import { validatelogin } from '../../hooks/validation';
import Connectwallet from "../Modals/ConnectWallet";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const AdminLogin = () =>{
  const [email , setEmail] = useState("");
  const [password , setPassword] = useState("");
  const [errors , seterrors] = useState();
  const [passwordeye, setpasswordeye] = useState(false);
  const [walletmodal , setWalletmodal] = useState(false);

const navigate = useNavigate()

useEffect(()=>{
  if(cookies.get("admin-email")){
   navigate("/");
  }
//   async function fetchdata(){
    
//    console.log("unlocked",await window?.ethereum?._metamask.isUnlocked())

//     if(!await window?.ethereum?._metamask.isUnlocked() || !localStorage.getItem("accountInfo") || !sessionStorage.getItem("accountInfo")){
//       setWalletmodal(true)
//     }
//   }
//  fetchdata();
},[])
  const handlelogin = async()=>{
    console.log("login");
    let payload = {
      email : email,
      password : password
    };
    var validate = await validatelogin(payload);
    if (!validate?.isValid) {
      seterrors(validate?.errors)
  }else{

    await loginhook(payload).then((result)=>{
      console.log("result" , result);
      if(result?.data?.type == "success"){
        navigate("/");
      // window.alert("Please connect your wallet!")
      window.location.reload();
      }
      else if(result?.data?.type == "error"){
        toast.error(result?.data?.record)
      }
    })
  }
    
  }

 

  const showPassword = (e) => {

    var e = document.getElementById("Password");
    if (e.type === "password") {
        setpasswordeye(true)
        e.type = "text";
    } else {
        setpasswordeye(false)
        e.type = "password";
    }
};

    return(
        <div className='adminlogin'>
             <div className='containerlogin'>
                <div className='row justify-content-center mh-100vh align-items-center m-sm-0 m-0'>
                    <div className='shadowbox col-lg-6 col-xl-5 col-md-6 bg-white br-10'>
                            <div className='py-1'>
                                <h3 className=' text-center mb-4'><img src={logo} className="loginicon"/></h3>
                                <h4 className='text-center f-600 mb-4'>Admin Panel</h4>
                                <h3 className='text-center f-600 mb-4'>Control Panel Login</h3>
                               
                                <div className='px-lg-5 px-3 h-55'>
                                     
                                {walletmodal && <Connectwallet  onDismiss={()=>setWalletmodal(false)} /> }
                                    <InputGroup className="mb-4" autoComplete='off'>
        <InputGroup.Text id="basic-addon1" ><span className='fa fa-user'></span></InputGroup.Text>
        <Form.Control
          placeholder="Username"
          aria-label="Username"
          autoComplete='off'
          aria-describedby="basic-addon1"
          value = {email}
          onChange={(e)=>{setEmail(e?.target?.value)}}/>
          
      </InputGroup>
      <span className="text-danger f-12 d-block text-left">{errors?.email}</span>

      <InputGroup className="mb-3">
        <InputGroup.Text ><span className='fa fa-key'></span></InputGroup.Text>
        <Form.Control placeholder='Enter your password' aria-label="Amount (to the nearest dollar)" type="password"
        value={password}
        id="Password"
        onChange={(e)=>{setPassword(e?.target?.value)}}
        autoComplete='off'/>
        {!passwordeye ? 
        <InputGroup.Text><span className='fa fa-eye-slash' onClick={showPassword}></span></InputGroup.Text>
        :<InputGroup.Text><span className='fa fa-eye' onClick={showPassword}></span></InputGroup.Text>
        }
      </InputGroup>
      <span className="text-danger f-12 d-block text-left">{errors?.password}</span>
                                    
                                  <div className='mt-5 text-center'>
                                       <button className='btn btn-theme aquabtn' onClick={handlelogin}>Login</button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default AdminLogin